<script>
	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';
	import { _, locale } from 'svelte-i18n';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import TitleBar from '../../lib/TitleBar.svelte';
	import { data, showSpinner, notification } from '../../stores.js';
	import { postWizard, getProfile, keepAlive } from '../../utils/api-calls.js';
	import WizardProgress from '../../lib/WizardProgress.svelte';
	import Notification from '../../lib/Notification.svelte';
	import {
		logPlausibleEvent,
		clearLocalAndSessionStorage,
		setAttributes
	} from '../../utils/helper.js';
	import InfoIcon from '../../lib/icon/InfoIcon.svelte';
	import SpinnerIcon from '../../lib/icon/SpinnerIcon.svelte';

	let nextPageAjax = false;

	onMount(async () => {
		$showSpinner = true;

		//we dont have profile data
		if (!$data?.version) {
			try {
				$data = await getProfile();

				if (!$data.isUserLoggedIn) {
					clearLocalAndSessionStorage();
					return replaceRoute('/login');
				}

				if ($data.profile?.actions?.doneWizardAt) return replaceRoute('/');

				//we do not have 2 recoveries
				if (
					$data.profile?.accounts?.filter((i) => i.recovery && !i.preferred && !i.communal).length <
					2
				)
					return replaceRoute('/wizard/incomplete');
			} catch {
				return replaceRoute('/login');
			}
		}

		localStorage.setItem('currentWizardStage', 'status');

		logWizardFunnelPlausibleEvent();
		logPlausibleEvent({ u: '/wizard/status' });

		$showSpinner = false;
	});

	function logWizardFunnelPlausibleEvent() {
		//Wizard Funnel
		const indexOfCurrentFunnelStep = window.wizardFunnel.indexOf(sessionStorage.wiz_funnel);
		const indexOfNextFunnelStep = window.wizardFunnel.indexOf('wiz_status');
		//wizard funnel state is valid and not already sent
		if (indexOfCurrentFunnelStep !== -1 && indexOfNextFunnelStep > indexOfCurrentFunnelStep) {
			const preferred = $data?.preferred?.[0]?.slug;
			let welcome_email_app;
			if (sessionStorage.welcome_app_info) {
				try {
					welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
				} catch (err) {
					console.error(err);
				}
			}
			logPlausibleEvent({
				n: 'Wiz Status',
				p: { preferred, welcome_email_app },
				u: '/wizard/status'
			});
			sessionStorage.setItem('wiz_funnel', 'wiz_status');
		}
	}

	async function nextPage() {
		keepAlive();
		nextPageAjax = true;
		const recoveries = $data.profile?.accounts.filter(
			(i) => i.recovery && !i.preferred && !i.communal
		);
		if (recoveries.length >= 2) {
			if (!$data.profile.actions?.doneWizardAt) {
				try {
					await postWizard();
					//End of Wizard Funnel
					if (sessionStorage.wiz_funnel === 'wiz_recovery_2_success') {
						const preferred = $data?.preferred?.[0]?.slug;
						let welcome_email_app;
						if (sessionStorage.welcome_app_info) {
							try {
								welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
							} catch (err) {
								console.error(err);
							}
						}
						const recovery_1 = $data?.recovery?.[0]?.slug;
						const recovery_2 = $data?.recovery?.[1]?.slug;
						await logPlausibleEvent({
							n: 'Wiz Complete',
							u: '/status',
							p: { preferred, welcome_email_app, recovery_1, recovery_2 }
						});
						sessionStorage.removeItem('wiz_funnel');
					}
				} catch (err) {
					nextPageAjax = false;
					console.error(err);
				}
			}
			try {
				const profile_data = await getProfile();
				$data = profile_data;
				localStorage.setItem('showProfileLandingModal', true);
				localStorage.removeItem('currentWizardStage');
				$notification = {
					text: '',
					type: ''
				};
				navigateTo('/');
			} catch (err) {
				nextPageAjax = false;
				console.error(err);
			}
		} else {
			return navigateTo('/wizard/recoveryprovider');
		}
	}

	const delay = 50;

	const handleEnterKey = (e) => {
		if (e.key === 'Enter') {
			nextPage();
		}
	};

	function skip() {
		keepAlive();
		const preferred = $data.profile?.accounts?.find((i) => i.preferred);
		const nonSocialProviders = ['email', 'phone'];
		const isPreferredSocial = !nonSocialProviders.includes(preferred.slug);
		const socialAccountLinked = $data.profile?.accounts.filter(
			(i) => ![...nonSocialProviders, 'ethereum'].includes(i.slug)
		).length;
		const recoveries = $data.profile?.accounts.filter(
			(i) => i.recovery && !i.preferred && !i.communal
		);

		if (!isPreferredSocial && socialAccountLinked) {
			return navigateTo('/wizard/upgrade');
		} else if (recoveries.length < 2) {
			return navigateTo('/wizard/incomplete');
		} else {
			return navigateTo('/wizard/status');
		}
	}
</script>

<TitleBar />

{#if $notification.text}
	<Notification />
{/if}

<svelte:window on:keydown={handleEnterKey} />

{#if $data?.isUserLoggedIn && !$showSpinner}
	{@const recoveryProviders = $data?.profile?.accounts?.filter((i) => i.recovery && !i.preferred)}
	<main class="flex-1 overflow-y-auto pb-16">
		<div class="mt-8">
			<div
				class="max-w-2xl mx-auto px-3 flex items-center justify-between space-x-2 md:space-x-0 text-sm"
				in:fade={{ delay: 0 * delay }}
			>
				<h1 class="text-center flex-1 border-none text-xl font-medium">
					{$_("This is how you'll be logging in")}
				</h1>
			</div>
			<div class="px-3 md:px-0 md:max-w-2xl container mx-auto text-center">
				<WizardProgress />

				<button
					data-test="status-continue-btn"
					on:click={nextPage}
					disabled={nextPageAjax}
					class="{!nextPageAjax
						? 'py-2.5 h-auto'
						: ''} btn-background max-w-md text-lg w-full inline-flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
					in:fade={{ delay: 4 * delay }}
				>
					{#if nextPageAjax}
						<SpinnerIcon css="h-5 w-5" />
					{:else if recoveryProviders?.length >= 2}
						{$_('Continue')}
					{:else if recoveryProviders?.length === 1}
						{$_('Add second recovery provider')}
					{:else}
						{$_('Add recovery providers')}
					{/if}
				</button>

				<div
					class="max-w-md opacity-80 w-full mx-auto flex items-center my-4 text-sm gap-x-2"
					in:fade={{ delay: 5 * delay }}
				>
					<InfoIcon />
					<span class={$locale && $locale.startsWith('ar') ? 'text-right' : 'text-left'}
						>{$_('You can change your providers at wallet.hello.coop')}</span
					>
				</div>

				{#if recoveryProviders?.length < 2}
					<button
						on:click={skip}
						data-test="skip-to-profile-btn"
						class="btn-border w-full max-w-md"
						in:fade={{ delay: 6 * delay }}
					>
						{$_('Skip to profile')}
					</button>
				{/if}
			</div>
		</div>
	</main>

	<wc-footer use:setAttributes />
{/if}
