<script>
	import { _ } from 'svelte-i18n';
	import { onMount } from 'svelte';
	import SpinnerIcon from './icon/SpinnerIcon.svelte';

	export let addName;
	export let type = '';
	let value = '';
	export let ajaxRequest = false;

	onMount(() => {
		if (type) document.getElementById(type).focus();
	});
</script>

<div class="text-center">
	<form on:submit|preventDefault={() => addName(value)}>
		<div>
			<div class="my-4">
				<input
					type="text"
					name={type}
					id={type}
					bind:value
					autocomplete={type === 'nickname'
						? 'nickname'
						: type === 'given_name'
						? 'given-name'
						: type === 'family_name'
						? 'family-name'
						: 'name'}
					required
					placeholder={type === 'nickname'
						? $_('Preferred Name')
						: type === 'given_name'
						? $_('First Name')
						: type === 'family_name'
						? $_('Last Name')
						: $_('Full Name')}
					class="pl-[16px] sm:pl-[18px] w-full h-12"
				/>
			</div>
			<button
				data-test="confirm-add-name-btn"
				type="submit"
				disabled={value.length <= 1 || ajaxRequest}
				class="disabled:opacity-50 disabled:cursor-not-allowed transition btn-background h-12 w-full"
			>
				{#if ajaxRequest}
					<SpinnerIcon css="h-5 w-5 mx-auto text-white" />
				{:else}
					{$_('Add name')}
				{/if}
			</button>
		</div>
	</form>
</div>
