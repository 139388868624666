<script>
	import { onDestroy } from 'svelte';
	import { _ } from 'svelte-i18n';
	import SpinnerIcon from '../icon/SpinnerIcon.svelte';

	export let value = '';
	export let isLoading = false;
	export let dataTest;

	$: {
		isLoading
			? document.body.classList.add('cursor-wait')
			: document.body.classList.remove('cursor-wait');
	}

	onDestroy(() => {
		document.body.classList.remove('cursor-wait');
	});
</script>

<button
	data-test={dataTest}
	on:click
	class="btn-border font-bold text-xs h-6 w-32 px-1 truncate uppercase inline-flex items-center justify-center uppercase"
	class:bg-opacity-50={isLoading}
	class:cursor-not-allowed={isLoading}
	disabled={isLoading}
>
	{#if isLoading}
		<SpinnerIcon css="h-4 w-4" />
	{:else}
		<span class="truncate">{$_(value)}</span>
	{/if}
</button>
