<!-- 
  Wallet profile page
  Root page of wallet SPA
-->

<script>
	import { _, locale, locales } from 'svelte-i18n';
	import { fly, slide } from 'svelte/transition';
	import TitleBar from '../../lib/TitleBar.svelte';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import Modal from '../../lib/modal/Modal.svelte';
	import { notification, data as profileData, showSpinner } from '../../stores.js';
	import { onMount } from 'svelte';
	import dayjs from 'dayjs';
	import relativeTime from 'dayjs/plugin/relativeTime';
	import preParsePostFormat from 'dayjs/plugin/preParsePostFormat';
	import 'dayjs/locale/ar';
	import 'dayjs/locale/fr';
	import 'dayjs/locale/es';
	import 'dayjs/locale/hi';
	import 'dayjs/locale/de';
	import LoginProvider from '../../lib/LoginProvider.svelte';
	import Dropdown from '../../lib/Dropdown.svelte';
	import VerifyName from '../../lib/VerifyName.svelte';
	import VerifyEmail from '../../lib/VerifyEmail.svelte';
	import VerifyPhone from '../../lib/VerifyPhone.svelte';
	import MailIcon from '../../lib/icon/MailIcon.svelte';
	import AppleIcon from '../../lib/icon/AppleIcon.svelte';
	import AndroidIcon from '../../lib/icon/AndroidIcon.svelte';
	import LinuxIcon from '../../lib/icon/LinuxIcon.svelte';
	import WindowsIcon from '../../lib/icon/WindowsIcon.svelte';
	import HelpButton from '../../lib/button/HelpButton.svelte';
	import MobileIcon from '../../lib/icon/MobileIcon.svelte';
	import TabletIcon from '../../lib/icon/TabletIcon.svelte';
	import CloseIcon from '../../lib/icon/CloseIcon.svelte';
	import DesktopIcon from '../../lib/icon/DesktopIcon.svelte';
	import PhoneIcon from '../../lib/icon/PhoneIcon.svelte';
	import logins from '../../../../../svr/providers/logins.json';
	import parser from 'ua-parser-js';
	import LoginProviderGroup from '../../lib/LoginProviderGroup.svelte';
	import { flip } from 'svelte/animate';
	import { RemoveIconButton, RemoveButton } from '../../lib/button/';
	import EthereumProgressModal from '../../lib/modal/EthereumProgressModal.svelte';
	import Notification from '../../lib/Notification.svelte';
	import * as sort from '../../utils/sort.js';
	import {
		logPlausibleEvent,
		clearLocalAndSessionStorage,
		setAttributes,
		getWallet,
		getDisplay,
		trimEthAddress,
		dedupe,
		promptForPasskey,
		updateAccounts
	} from '../../utils/helper.js';
	import {
		getProfile,
		postLinkProvider,
		putName,
		putPreferred,
		deleteProfile,
		deletePicture,
		deleteName,
		deleteProvider,
		deleteUnverifiedProvider,
		deleteDevice,
		deleteApplication,
		postLinkEth,
		postLinkEthChallenge,
		postPicture,
		keepAlive,
		changeLanguage
	} from '../../utils/api-calls.js';
	import tippy from 'sveltejs-tippy';
	import HandlePicture from '../../lib/modal/HandlePictureModal.svelte';
	import { WALLETCONNECT_CONFIG } from '../../constants.js';
	import { getAddressFromAccount } from '@walletconnect/utils';
	import { WalletConnectModalSign } from '@walletconnect/modal-sign-html';
	import SpinnerIcon from '../../lib/icon/SpinnerIcon.svelte';
	import SessionTimerModal from '../../lib/modal/SessionTimerModal.svelte';
	import PromoteIcon from '../../lib/icon/PromoteIcon.svelte';
	import DemoteIcon from '../../lib/icon/DemoteIcon.svelte';
	import NotAllowedIcon from '../../lib/icon/NotAllowedIcon.svelte';
	import FullPageModal from '../../lib/modal/FullPageModal.svelte';

	let web3ModalSign;

	let editMode = false,
		confirmFullNameDelete,
		confirmPreferredNameDelete,
		confirmFirstNameDelete,
		confirmLastNameDelete,
		confirmPictureDelete,
		confirmProviderDelete,
		confirmDeviceDelete,
		confirmApplicationDelete,
		verifyProvider = null,
		verifyProviderAuthority = null,
		confirmProfileDelete = false;

	let dropdownStates = {
		provider: false,
		language: false,
		picture: false,
		email: false,
		phone: false
	};

	let remoteAuthDone = false;

	let showAddNameModal = false,
		showAddFullNameModal = false,
		showAddPreferredNameModal = false,
		showAddFirstNameModal = false,
		showAddLastNameModal = false;

	let deleteConfirmationInput = '';
	let removePictureAjax = null;
	let removeFullNameAjax = null;
	let removePreferredNameAjax = null;
	let removeFirstNameAjax = null;
	let removeLastNameAjax = null;
	let removeProviderAjax = null;
	let removeUnverifiedProviderAjax = null;
	let removeDeviceAjax = null;
	let removeApplicationAjax = null;
	let uploadPictureAjax = false;
	let showProfileLandingModal = false;

	let emailOTPState = false;
	let phoneOTPState = false;

	let ethereumProgressModal = null; //Will hold extension or walletconnect values
	let ethereumProgressNotifs = [];

	dayjs.extend(preParsePostFormat);
	dayjs.extend(relativeTime);

	let oauth_error = false;

	const searchParams = new URLSearchParams(window.location.search);
	const hashParams = new URLSearchParams(window.location.hash.substring(1));

	onMount(async () => {
		$showSpinner = true;

		const errorFromProvider = hashParams.get('error');
		const responseFromProvider = hashParams.get('provider');
		const loadedFromWelcomeEmail = searchParams.has('welcome');

		if (errorFromProvider) {
			//LINE returns ACCESS_DENIED (uppercase)
			//Apple returns user_cancelled_authorize
			if (
				['access_denied', 'user_cancelled_authorize'].includes(errorFromProvider?.toLowerCase())
			) {
				$notification = {
					text: $_('Request cancelled'),
					type: 'error'
				};
			} else {
				$notification = {
					text: $_('Something went wrong. Please try again later.'),
					type: 'error'
				};
			}
			oauth_error = true;

			//User can cancel authorize flow on linking provider - make getProfile call since user can be still logged in
			try {
				$profileData = await getProfile('', { showNotification: !oauth_error });
			} catch (err) {
				return showLogin();
			}
		} else if (responseFromProvider) {
			try {
				$profileData = await getProfile(hashParams.toString(), { showNotification: !oauth_error });
				logProviderResPlausibleEvents();
			} catch (err) {
				console.error(err);
			}
		} else if (loadedFromWelcomeEmail) {
			try {
				$profileData = await getProfile(searchParams.toString(), {
					showNotification: !oauth_error
				});
			} catch {
				window.history.replaceState({}, document.title, window.location.pathname);
				return replaceRoute('/login');
			}

			//app info for welcome page and plausible events
			const welcome_app_info = {
				name: searchParams.get('name'),
				image_uri: searchParams.get('image_uri'),
				dark_image_uri: searchParams.get('dark_image_uri')
			};
			sessionStorage.setItem('welcome_app_info', JSON.stringify(welcome_app_info));
		} else if (!$profileData?.version) {
			//we dont have profile data
			try {
				$profileData = await getProfile('', { showNotification: !oauth_error });
			} catch {
				return showLogin();
			}
		}

		//Done processing query/hash params the page got loaded with
		//Clear url query params (oauth errors, provider response, welcome email params)
		window.history.replaceState({}, document.title, window.location.pathname);

		//Authorization success at IAB
		if ($profileData.inApp) {
			remoteAuthDone = true;
			$notification = {
				text: 'Authorization is complete',
				type: 'success'
			};
			$showSpinner = false;
			return;
		}

		if (!$profileData.isUserLoggedIn) return showLogin();

		if (!$profileData.profile?.actions?.doneWizardAt) {
			//Determine next step in wizard after linking provider
			const WIZARD_STAGES = ['welcome', 'status', 'recoveryprovider', 'incomplete', 'upgrade'];
			const currentWizardStage = localStorage.getItem('currentWizardStage');
			if (WIZARD_STAGES.includes(currentWizardStage)) {
				// get our current state of preferred and recovery providers
				const preferred = $profileData.profile?.accounts?.find((i) => i.preferred);
				const UPGRADEABLE_PROVIDERS = ['email', 'phone'];
				const NON_RECOMMENDED_PROVIDERS = [...UPGRADEABLE_PROVIDERS, 'ethereum'];
				const isUpgradable = UPGRADEABLE_PROVIDERS.includes(preferred.slug);
				const socialAccountLinked = $profileData.profile?.accounts.filter(
					(i) => !NON_RECOMMENDED_PROVIDERS.includes(i.slug)
				).length;
				const recoveries = $profileData.profile?.accounts.filter((i) => i.recovery && !i.preferred);

				if (recoveries.length >= 2) {
					// we have all the recoveries we need
					if (isUpgradable && socialAccountLinked) return replaceRoute('/wizard/upgrade');
					else return replaceRoute('/wizard/status');
				}
				return replaceRoute('/wizard/' + currentWizardStage);
			}
			return replaceRoute('/wizard/' + WIZARD_STAGES[0]);
		}

		const showPromptForPasskey = await promptForPasskey($profileData);
		if (showPromptForPasskey) return replaceRoute('/passkey');

		//Handle outstanding make preferred flow after verification of provider
		let authority;
		try {
			authority = JSON.parse(sessionStorage.getItem('authority'));
		} catch (err) {
			console.warn('Bad data in authority session storage. Clearing...');
			sessionStorage.getItem('authority');
		}
		if (authority) {
			try {
				$showSpinner = true;
				const preferred = authority.preferred;
				const recovery = authority.recovery;
				const no_recovery = authority.no_recovery;
				const account = $profileData.profile.accounts.find(
					(i) => i.id === (preferred || recovery || no_recovery)
				);
				if (preferred) {
					await makePreferred(account);
				} else if (recovery) {
					await makeRecovery(account);
				} else if (no_recovery) {
					await removeRecovery(account);
				}
			} catch (err) {
				// nothing we can do to recover
				console.error(err);
			} finally {
				sessionStorage.removeItem('authority');
				$showSpinner = false;
			}
		}

		//Only add unverified emails/phone to accounts upon wizard completion
		if ($profileData.profile?.actions?.doneWizardAt) {
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
		}

		logPlausibleEvent({ u: '/' });

		if (localStorage.showProfileLandingModal) {
			showProfileLandingModal = true;
		}
		if (sessionStorage.isInEditMode) {
			editMode = true;
		}

		$showSpinner = false;
	});

	function handleDropdown(toggledDropdown) {
		for (const i in dropdownStates) {
			if (i === toggledDropdown) {
				dropdownStates[i] = !dropdownStates[i];
				continue;
			}
			dropdownStates[i] = false;
		}
	}

	function showLogin() {
		clearLocalAndSessionStorage();
		return replaceRoute('/login');
	}

	function logProviderResPlausibleEvents() {
		//End of Email Upgrade Funnel
		//email upgrade funnel state is valid and not already sent + log in success
		if (
			sessionStorage.email_upgrade_funnel === 'email_upgrade_start' &&
			$profileData.isUserLoggedIn
		) {
			const email_domain = $profileData?.preferred?.[0]?.user_name?.split('@')[1];
			logPlausibleEvent({
				n: 'Email Upgrade Success',
				p: { email_domain, slug: hashParams.get('provider') },
				u: '/'
			});
			sessionStorage.removeItem('email_upgrade_funnel');
		}

		//Wizard Funnel
		const isInWizard = !$profileData?.profile?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
		if (isInWizard) {
			const preferred = $profileData?.preferred?.[0]?.slug;
			let welcome_email_app;
			if (sessionStorage.welcome_app_info) {
				try {
					welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
				} catch (err) {
					console.error(err);
				}
			}
			const recovery_1 = $profileData?.recovery?.[0]?.slug;
			const recovery_2 = $profileData?.recovery?.[1]?.slug;
			if (sessionStorage.wiz_funnel === 'wiz_recovery_1_start') {
				logPlausibleEvent({
					n: 'Wiz Recovery 1 Success',
					p: { preferred, welcome_email_app, recovery_1 },
					u: '/'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_success');
			} else if (sessionStorage.wiz_funnel === 'wiz_recovery_2_start') {
				logPlausibleEvent({
					n: 'Wiz Recovery 2 Success',
					p: { preferred, welcome_email_app, recovery_1, recovery_2 },
					u: '/'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_success');
			}
		}
	}

	let addFullNameAjax = false;
	async function addFullName(value) {
		addFullNameAjax = true;
		try {
			const { names } = await putName('name', value);
			$profileData.profile.names = names;
			showAddNameModal = showAddFullNameModal = false;
		} catch (err) {
			console.error(err);
		} finally {
			addFullNameAjax = false;
		}
	}

	let addPreferredNameAjax = false;
	async function addPreferredName(value) {
		addPreferredNameAjax = true;
		try {
			const { nicknames } = await putName('nickname', value);
			$profileData.profile.nicknames = nicknames;
			showAddPreferredNameModal = false;
		} catch (err) {
			console.error(err);
		} finally {
			addPreferredNameAjax = false;
		}
	}

	let addFirstNameAjax = false;
	async function addFirstName(value) {
		addFirstNameAjax = true;
		try {
			const { given_names } = await putName('given_name', value);
			$profileData.profile.given_names = given_names;
			showAddFirstNameModal = false;
		} catch (err) {
			console.error(err);
		} finally {
			addFirstNameAjax = false;
		}
	}

	let addLastNameAjax = false;
	async function addLastName(value) {
		addLastNameAjax = true;
		try {
			const { family_names } = await putName('family_name', value);
			$profileData.profile.family_names = family_names;
			showAddLastNameModal = false;
		} catch (err) {
			console.error(err);
		} finally {
			addLastNameAjax = false;
		}
	}

	async function removePicture(index) {
		try {
			removePictureAjax = confirmPictureDelete;
			const { pictures } = await await deletePicture(index);
			$profileData.profile.pictures = pictures;
			confirmPictureDelete = null;
		} catch (err) {
			console.error(err);
		} finally {
			removePictureAjax = null;
		}
	}

	async function toggleEdit() {
		keepAlive();
		//FIX
		handleDropdown(); //closes all dropdown states
		confirmFullNameDelete =
			confirmPreferredNameDelete =
			confirmFirstNameDelete =
			confirmLastNameDelete =
			confirmPictureDelete =
			confirmProviderDelete =
			showAddNameModal =
			showAddFullNameModal =
			showAddPreferredNameModal =
			showAddFirstNameModal =
			showAddLastNameModal =
			verifyProvider =
			verifyProviderAuthority =
			confirmProfileDelete =
				false;
		deleteConfirmationInput = '';

		if (editMode) {
			editMode = false;
			sessionStorage.removeItem('isInEditMode');
		} else {
			logPlausibleEvent({ u: '/edit', n: 'action' });
			editMode = true;
			sessionStorage.setItem('isInEditMode', true);
		}
	}

	function tryRemove(value) {
		if (value.includes('default-picture.png')) {
			return;
		}

		confirmPictureDelete = value;
	}

	const makeAuthorityNotification = (account, text) => {
		if (account.slug === 'ethereum') {
			return $_(text, {
				values: {
					provider: account.wallet?.name,
					label: account.user_name || ''
				}
			});
		} else if (account.slug === 'email') {
			return $_(text, {
				values: {
					provider: $_('Email'),
					label: account.user_name || ''
				}
			});
		} else if (account.slug === 'phone') {
			return $_(text, {
				values: {
					provider: $_('Phone'),
					label: account.user_name || ''
				}
			});
		} else {
			return $_(text, {
				values: {
					provider: getDisplay(account.slug),
					label: account.user_name || ''
				}
			});
		}
	};

	async function makePreferred(account) {
		await putAuthority(
			account,
			{ preferred: account.id },
			makeAuthorityNotification(account, '{provider} {label} is now preferred')
		);
	}

	async function makeRecovery(account) {
		await putAuthority(
			account,
			{ recovery: account.id },
			makeAuthorityNotification(account, '{provider} {label} is now a recovery')
		);
	}

	async function removeRecovery(account) {
		await putAuthority(
			account,
			{ no_recovery: account.id },
			makeAuthorityNotification(account, '{provider} {label} has been removed as a recovery')
		);
	}

	async function putAuthority(account, action, notificationText) {
		try {
			const isAccountSocial = !['ethereum', 'email', 'phone'].includes(account.slug);
			const res = await putPreferred(action, {
				redirectPathParam: isAccountSocial, // only send redirect_path param if social provider
				server: account.mastodonServer || null // only send server param if mastodon
			});
			$profileData.profile.accounts = res.accounts;
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
			$notification = {
				text: notificationText,
				type: 'success'
			};
		} catch (err) {
			if (err.status === 401) {
				const json = await err.json();
				if (json.verify) {
					console.info('Subject is not authenticated, showing verify modal.');
					verifyProviderAuthority = { id: account.id, ...json.verify, action };
					if (verifyProviderAuthority.slug === 'ethereum') {
						verifyProviderAuthority.wallet = $profileData.profile.accounts.find(
							(i) => i.id === verifyProviderAuthority.id
						).wallet;
					} else if (verifyProviderAuthority.slug === 'mastodon') {
						//Do not show Mastodon dropdown UI - show continue with Mastodon account button
						verifyProviderAuthority.mastodonServer = $profileData.profile.accounts.find(
							(i) => i.id === verifyProviderAuthority.id
						).mastodonServer;
						verifyProviderAuthority.user_name = $profileData.profile.accounts.find(
							(i) => i.id === verifyProviderAuthority.id
						).user_name;
					}
				}
			} else {
				console.error(err);
			}
		}
	}

	async function verifyEmailSuccess() {
		try {
			$profileData = await getProfile();
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
			if (!$profileData.merge) {
				if (verifyProvider) {
					$notification = {
						text: $_('{contact} has been verified', {
							values: {
								contact: verifyProvider
							}
						}),
						type: 'success'
					};
				}
				verifyProvider = null;
			}
			dropdownStates.email = false;
		} catch (err) {
			console.error(err);
		}
	}

	async function verifyPhoneSuccess() {
		try {
			$profileData = await getProfile();
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
			if (!$profileData.merge) {
				if (verifyProvider) {
					$notification = {
						text: $_('{contact} has been verified', {
							values: {
								contact: window.intlTelInputUtils.formatNumber(
									verifyProvider,
									null,
									window.intlTelInputUtils.numberFormat.INTERNATIONAL
								)
							}
						}),
						type: 'success'
					};
				}
				verifyProvider = null;
			}
			dropdownStates.phone = false;
		} catch (err) {
			console.error(err);
		}
	}

	function verifyContactError() {
		verifyProvider = null;
	}

	async function verifyContactAuthoritySuccess() {
		const account = $profileData.profile.accounts.find((i) => i.id === verifyProviderAuthority.id);
		try {
			if (verifyProviderAuthority.action.preferred) {
				await makePreferred(account);
			} else if (verifyProviderAuthority.action.recovery) {
				await makeRecovery(account);
			} else if (verifyProviderAuthority.action.no_recovery) {
				await removeRecovery(account);
			}
		} catch (err) {
			console.error(err);
		} finally {
			verifyProviderAuthority = null;
		}
	}

	async function verifyProviderForAuthority() {
		try {
			$showSpinner = true;
			sessionStorage.setItem('authority', JSON.stringify(verifyProviderAuthority.action));
			const account = $profileData.profile.accounts.find(
				(i) => i.id === verifyProviderAuthority.id
			);
			await logPlausibleEvent({ u: `/start/verify/${account.slug}`, n: 'action' });
			window.location.href = verifyProviderAuthority.redirect_uri;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	async function verifyEthereumForAuthority() {
		const account = $profileData.profile.accounts.find((i) => i.id === verifyProviderAuthority.id);
		const action = verifyProviderAuthority.action;
		verifyProviderAuthority = null; //hide verify modal to show ethereum signing state modal
		const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
		ethereumProgressModal = 'extension';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign...')
			}
		];
		const verify = true;
		await continueEthExtensionSigning(address, verify);
		if (action.preferred) {
			await makePreferred(account);
		} else if (action.recovery) {
			await makeRecovery(account);
		} else if (action.no_recovery) {
			await removeRecovery(account);
		}
	}

	// async function verifyWalletConnectForPreferred() {
	// if(!web3ModalSign) {
	// 	try {
	// 		web3ModalSign = new WalletConnectModalSign(WALLETCONNECT_CONFIG);
	// 	} catch(err) {
	// 		console.error(err)
	// 		setTimeout(() => { //tbd : remove timeout - something is unsetting notification here
	// 			$notification = {
	// 			text: 'Something went wrong',
	// 				type: 'error'
	// 			}
	// 		}, 150)
	// 		return;
	// 	}
	// }

	// if (session) {
	// 	await web3ModalSign.disconnect({
	// 		topic: session.topic
	// 	});
	// }
	// session = await web3ModalSign.connect({
	// 	requiredNamespaces: {
	// 		eip155: {
	// 			methods: ['personal_sign'],
	// 			chains: ['eip155:1'],
	// 			events: []
	// 		}
	// 	}
	// });
	// const address = getAddressFromAccount(session.namespaces.eip155.accounts[0]);
	// ethereumProgressModal = 'walletconnect';
	// ethereumProgressNotifs = [
	// 	...ethereumProgressNotifs,
	// 	{
	// 		text: $_('Wallet Connected ({address})', {
	// 			values: {
	// 				address: trimEthAddress(address)
	// 			}
	// 		}),
	// 		type: 'success',
	// 		status: $_('Waiting to sign...')
	// 	}
	// ];
	// //TODO WalletConnect v2 bug: https://github.com/wagmi-dev/wagmi/issues/2631
	// setTimeout(async () => {
	// 	continueWalletConnectSigning(address, true);
	// }, 1000);
	// }

	async function continueWithProvider(account, attribute, server) {
		try {
			$showSpinner = true;
			const { redirect } = await postLinkProvider({
				slug: account.slug,
				attribute,
				server
			});
			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	async function removeDevice() {
		try {
			removeDeviceAjax = confirmDeviceDelete;
			await deleteDevice(confirmDeviceDelete);
			$profileData = await getProfile();
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
			confirmDeviceDelete = null;
		} catch (err) {
			console.error(err);
		} finally {
			removeDeviceAjax = null;
		}
	}

	async function removeApplication() {
		try {
			removeApplicationAjax = confirmApplicationDelete;
			await deleteApplication(confirmApplicationDelete);
			$profileData = await getProfile();
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
			confirmApplicationDelete = null;
		} catch (err) {
			console.error(err);
		} finally {
			removeApplicationAjax = null;
		}
	}

	async function removeFullName(index) {
		try {
			removeFullNameAjax = confirmFullNameDelete;
			const { names } = await deleteName('name', index);
			$profileData.profile.names = names;
			confirmFullNameDelete = false;
		} catch (err) {
			console.error(err);
		} finally {
			removeFullNameAjax = null;
		}
	}

	async function removePreferredName(index) {
		try {
			removePreferredNameAjax = confirmPreferredNameDelete;
			const { nicknames } = await deleteName('nickname', index);
			$profileData.profile.nicknames = nicknames;
			confirmPreferredNameDelete = false;
		} catch (err) {
			console.error(err);
		} finally {
			removePreferredNameAjax = null;
		}
	}

	async function removeFirstName(index) {
		try {
			removeFirstNameAjax = confirmFirstNameDelete;
			const { given_names } = await deleteName('given_name', index);
			$profileData.profile.given_names = given_names;
			confirmFirstNameDelete = false;
		} catch (err) {
			console.error(err);
		} finally {
			removeFirstNameAjax = null;
		}
	}

	async function removeLastName(index) {
		try {
			removeLastNameAjax = confirmLastNameDelete;
			const { family_names } = await deleteName('family_name', index);
			$profileData.profile.family_names = family_names;
			confirmLastNameDelete = false;
		} catch (err) {
			console.error(err);
		} finally {
			removeLastNameAjax = null;
		}
	}

	let uploadedPicture = null;

	let handlePictureAjax = false;
	// Picture upload start
	async function uploadPicture(picture) {
		handlePictureAjax = true;
		const formDataObj = new FormData();
		formDataObj.append('file', picture);

		try {
			const { pictures } = await postPicture(formDataObj);
			$profileData.profile.pictures = pictures;
			dropdownStates.picture = false;
			// animateScroll.scrollToTop({
			// 	container: 'main'
			// })
		} catch (err) {
			console.error(err);
		} finally {
			uploadPictureAjax = false;
			handlePictureAjax = false;
		}
	}
	// Picture upload end

	async function removeProvider(id) {
		try {
			removeProviderAjax = confirmProviderDelete;
			const res = await deleteProvider(id);
			$profileData.profile.accounts = res.accounts;
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
			confirmProviderDelete = null;
		} catch (err) {
			console.error(err);
		} finally {
			removeProviderAjax = null;
		}
	}

	async function removeUnverifiedProvider(user_name) {
		try {
			removeUnverifiedProviderAjax = confirmProviderDelete;
			if ($profileData.profile.unverified_emails.includes(user_name)) {
				const index = $profileData.profile.unverified_emails.indexOf(user_name);
				await deleteUnverifiedProvider(index, 'email');
			} else {
				const index = $profileData.profile.unverified_phones.indexOf(user_name);
				await deleteUnverifiedProvider(index, 'phones');
			}
			$profileData = await getProfile();
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
			confirmProviderDelete = null;
		} catch (err) {
			console.error(err);
		} finally {
			removeUnverifiedProviderAjax = null;
		}
	}

	let delProfileAjax = false;
	async function delProfile() {
		try {
			delProfileAjax = true;
			await deleteProfile();
			$profileData = {};
			navigateTo('/login');
		} catch (err) {
			delProfileAjax = false;
			console.error(err);
		}
	}

	async function continueWithEthereumExtension() {
		const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
		ethereumProgressModal = 'extension';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign...')
			}
		];
		continueEthExtensionSigning(address);
	}

	async function continueEthExtensionSigning(address, verify) {
		let challenge, signature;

		try {
			const res = await postLinkEth(address);
			if (verify) {
				logPlausibleEvent({
					u: `/start/verify/ethereum/extension/${getWallet().slug}`,
					n: 'action'
				});
			} else {
				logPlausibleEvent({ u: `/start/link/ethereum/extension/${getWallet().slug}`, n: 'action' });
			}
			challenge = res.challenge;
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					status: $_('Waiting to sign...')
				}
			];
		} catch (err) {
			console.error(err);
		}

		try {
			signature = await window.ethereum.request({
				method: 'personal_sign',
				params: [address, challenge]
			});
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Message signed'),
					type: 'success',
					status: $_('Linking wallet...')
				}
			];
		} catch (err) {
			console.info(err);
			if (err.code === 4001) {
				$notification = {
					text: $_(`You've rejected the sign request`),
					type: 'error'
				};
			} else {
				$notification = {
					text: $_('Something went wrong. Please try again later.'),
					type: 'error'
				};
			}
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const body = {
			signature,
			address,
			icon: getWallet().icon,
			name: getWallet().name
		};

		try {
			await postLinkEthChallenge(body);
			if (verify) {
				logPlausibleEvent({ u: `/verify/ethereum/extension/${getWallet().slug}`, n: 'action' });
			} else {
				logPlausibleEvent({ u: `/link/ethereum/extension/${getWallet().slug}`, n: 'action' });
				$notification = {
					text: $_('{provider} {label} has been added', {
						values: {
							provider: body.name,
							label: trimEthAddress(address)
						}
					}),
					type: 'success'
				};
			}
			$profileData = await getProfile();
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
		} catch (err) {
			console.error(err);
		} finally {
			dropdownStates.provider = false;
			ethereumProgressModal = false;
			ethereumProgressNotifs = [];
		}
	}

	let session;
	async function continueWithWalletConnect() {
		if (!web3ModalSign) {
			try {
				web3ModalSign = new WalletConnectModalSign(WALLETCONNECT_CONFIG);
			} catch (err) {
				console.error(err);
				setTimeout(() => {
					//tbd : remove timeout - something is unsetting notification here
					$notification = {
						text: 'Something went wrong',
						type: 'error'
					};
				}, 150);
				return;
			}
		}

		if (session) {
			await web3ModalSign.disconnect({
				topic: session.topic
			});
		}
		session = await web3ModalSign.connect({
			requiredNamespaces: {
				eip155: {
					methods: ['personal_sign'],
					chains: ['eip155:1'],
					events: []
				}
			}
		});
		const address = getAddressFromAccount(session.namespaces.eip155.accounts[0]);
		ethereumProgressModal = 'walletconnect';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign...')
			}
		];
		//TODO WalletConnect v2 bug: https://github.com/wagmi-dev/wagmi/issues/2631
		setTimeout(() => {
			continueWalletConnectSigning(address);
		}, 1000);
	}

	async function continueWalletConnectSigning(address, verify) {
		let challenge, signature;
		const slug = session.peer.metadata.name.replace(/ /g, '-').toLowerCase();
		try {
			const res = await postLinkEth(address);
			if (verify) {
				logPlausibleEvent({ u: `/start/verify/ethereum/walletconnect/${slug}`, n: 'action' });
			} else {
				logPlausibleEvent({ u: `/start/link/ethereum/walletconnect/${slug}`, n: 'action' });
			}
			challenge = res.challenge;
		} catch (err) {
			console.error(err);
			$notification = {
				text: 'Something went wrong',
				type: 'error'
			};
			return;
		}
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				status: $_('Waiting to sign...')
			}
		];
		try {
			signature = await web3ModalSign.request({
				topic: session.topic,
				chainId: 'eip155:1',
				request: {
					method: 'personal_sign',
					params: [challenge, address]
				}
			});
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Message signed'),
					type: 'success',
					status: $_('Linking wallet...')
				}
			];
		} catch (err) {
			console.info(err);
			$notification = {
				text: $_(`You've rejected the sign request`),
				type: 'error'
			};
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const icon =
			session.peer.metadata.icons[0] ||
			(session.peer.metadata?.url === 'https://metamask.io/'
				? 'https://cdn.hello.coop/images/metamask.svg'
				: 'https://cdn.hello.coop/images/ethereum.svg');
		const body = {
			signature,
			address,
			icon,
			name: session.peer.metadata.name
		};

		try {
			await postLinkEthChallenge(body);
			if (verify) {
				logPlausibleEvent({
					u: `/verify/ethereum/walletconnect/${slug}`,
					n: 'action'
				});
			} else {
				logPlausibleEvent({
					u: `/link/ethereum/walletconnect/${slug}`,
					n: 'action'
				});
				$notification = {
					text: $_('{provider} {label} has been added', {
						values: {
							provider: body.name,
							label: trimEthAddress(address)
						}
					}),
					type: 'success'
				};
			}
			$profileData = await getProfile();
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
		} catch (err) {
			console.error(err);
		} finally {
			dropdownStates.provider = false;
			ethereumProgressModal = false;
			ethereumProgressNotifs = [];
		}

		if (verify) {
			try {
				const res = await putPreferred(verifyProviderAuthority.id);
				$profileData.profile.accounts = res.accounts;
				$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
				$notification = {
					text: $_('{provider} {label} is now preferred', {
						values: {
							provider: verifyProviderAuthority.wallet?.name,
							label: verifyProviderAuthority.user_name || ''
						}
					}),
					type: 'success'
				};
				verifyProviderAuthority = null;
			} catch (err) {
				console.error(err);
			}
		}
	}

	function closeModal() {
		ethereumProgressModal = verifyProvider = verifyProviderAuthority = uploadedPicture = null;
		ethereumProgressNotifs = [];
	}

	function closeLandingModal() {
		showProfileLandingModal = false;
		localStorage.removeItem('showProfileLandingModal');
		//Wait for modal to be popped
		setTimeout(() => {
			$notification = {
				text: `<span>${$_(
					'Learn more about Hellō at'
				)} <a href="https://www.hello.coop" target="_blank" class="underline">hello.coop</a></span>`,
				type: 'success'
			};
		}, 100);
	}

	$: recoveriesLength = $profileData?.profile?.accounts?.filter(
		(i) => !i.preferred && i.recovery && !i.communal
	)?.length;

	const flip_animation_ms = 2000;

	let changePreferredLanguageAjax = null;
	async function changePreferredLanguage(langShortCode) {
		try {
			changePreferredLanguageAjax = langShortCode;
			await changeLanguage(langShortCode);
			localStorage.setItem('lang', langShortCode);
			$locale = langShortCode;
			dropdownStates.language = false;
			setTimeout(() => {
				$notification = {
					text: $_('Preferred language is now {language}', {
						values: {
							language: $_(langShortCode)
						}
					}),
					type: 'success'
				};
			}, 150);
		} catch (err) {
			console.error(err);
		} finally {
			changePreferredLanguageAjax = null;
		}
	}

	function downloadData() {
		const downloadData = JSON.parse(JSON.stringify($profileData));
		delete downloadData.isUserLoggedIn;
		delete downloadData.preferred;
		delete downloadData.recovery;
		delete downloadData.subjects;
		const dataStr =
			'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(downloadData, null, 2));
		const dlAnchorElem = document.getElementById('downloadAnchorElem');
		dlAnchorElem.setAttribute('href', dataStr);
		let fileName = 'Hellō-Profile.json';
		dlAnchorElem.setAttribute('download', fileName);
		dlAnchorElem.click();
	}
</script>

{#if uploadedPicture}
	<HandlePicture
		imgSrc={uploadedPicture}
		bind:ajaxRequest={handlePictureAjax}
		on:cancel={() => (uploadedPicture = null)}
		on:upload={(e) => {
			uploadPicture(e.detail);
			uploadedPicture = null;
		}}
	/>
{/if}

{#if remoteAuthDone}
	{#if $notification.text}
		<Notification />
	{/if}
	<div class="px-3 mt-12 text-center max-w-md container mx-auto">
		{$_('You can now close this window')}
	</div>
{/if}

{#if Object.keys($profileData).length && $profileData.isUserLoggedIn && $profileData.profile.actions.doneWizardAt}
	{#if ethereumProgressModal && ethereumProgressNotifs.length}
		<EthereumProgressModal
			notifications={ethereumProgressNotifs}
			on:cancel={() => {
				ethereumProgressNotifs = [];
				ethereumProgressModal = null;
			}}
			on:ok={(e) => {
				if (ethereumProgressModal === 'extension') {
					continueEthExtensionSigning(e.detail);
				} else if (ethereumProgressModal === 'walletconnect') {
					continueWalletConnectSigning(e.detail);
				}
			}}
		/>
	{/if}

	{#if showProfileLandingModal}
		<FullPageModal on:close={closeLandingModal} dataTest="profile-landing-modal">
			<span class="mt-2 font-semibold text-lg">{$_('This is your Hellō Wallet')}</span>

			<p class="text-center mt-6">
				{$_('Here is where you can change your login providers and wallet information')}
			</p>

			<p class="text-center mt-6 font-semibold">wallet.hello.coop</p>

			<!-- svelte-ignore a11y-autofocus -->
			<button
				autofocus
				data-test="profile-landing-modal-ok-btn"
				on:click={closeLandingModal}
				class="btn-background w-full mt-6">{$_('OK')}</button
			>
		</FullPageModal>
	{/if}

	{#if !remoteAuthDone}
		<TitleBar profile {editMode} on:click={toggleEdit} />
	{/if}

	{#if $notification.text}
		<Notification />
	{/if}

	{#if !$showSpinner}
		<section class="w-full z-[35] bg-[#ebebeb] dark:bg-[#252525]">
			<!-- saturate(180%) blur(20px) -->
			<div class="px-3 py-4 max-w-2xl container mx-auto flex justify-between items-center">
				<div class="flex flex-col items-start">
					{#if $profileData.profile.names?.length}
						<h1 class="text-xl font-medium">{$profileData.profile.names[0]}</h1>
					{:else}
						<div class="relative">
							<button
								on:click={() => (showAddNameModal = true)}
								class="mb-1 btn-background h-6 px-2 text-sm">{$_('Add full name')}</button
							>

							{#if showAddNameModal}
								<Modal
									class="top-12 !w-[16rem]"
									position="left"
									on:close={() => (showAddNameModal = false)}
								>
									<VerifyName type="name" addName={addFullName} ajaxRequest={addFullNameAjax} />
								</Modal>
							{/if}
						</div>
					{/if}
					<h2 class="opacity-80 text-sm">
						{$_('Member since {date}', {
							values: {
								date: dayjs($profileData.profile.createdAt).locale($locale).format('MMMM DD, YYYY')
							}
						})}
					</h2>
				</div>

				<button
					data-test="profile-edit-btn"
					on:click={toggleEdit}
					class="px-2 inline-flex truncate items-center justify-center w-24 h-8 btn-border rounded-full gap-x-1"
				>
					{#if editMode}
						<CloseIcon />
						<span class="truncate">{$_('Close')}</span>
					{:else}
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-5 flex-shrink-0"
							viewBox="0 0 20 20"
							fill="currentColor"
						>
							<path
								d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
							/>
						</svg>
						<span class="truncate">{$_('Edit')}</span>
					{/if}
				</button>
			</div>
		</section>

		<main class="flex-1 pb-16 overflow-y-auto">
			<div class="px-3 max-w-2xl container mx-auto">
				<!-- Images -->
				<section id="pictures" class="py-2 mt-3">
					<h2 class="font-medium text-lg">{$_('Pictures')}</h2>
					<ul class="flex flex-col space-y-4 mt-2">
						{#if $profileData.profile.pictures?.length}
							{#each dedupe($profileData.profile.pictures, 'picture') as { label, picture, source, slug, createdAt, updatedAt }, index}
								<li class="relative flex-shrink-0 flex items-center">
									<!-- animate:flip={{ duration: flip_animation_ms }}
									transition:slide|local -->
									<div class="w-6 inline-flex items-center flex-shrink-0">
										{#if editMode && source !== 'default'}
											<RemoveIconButton on:click={() => tryRemove(picture)} />
										{/if}
									</div>
									<div class="flex items-center gap-x-2">
										<img
											src={picture}
											alt={source === 'default'
												? $_('Generic Hellō Profile Picture')
												: source === 'upload'
												? $_('Uploaded from device')
												: $_('From {provider}', {
														values: {
															provider: getDisplay(slug)
														}
												  })}
											class="h-12 w-12 rounded-full object-cover hide-alt-text flex-shrink-0"
											title={source === 'default'
												? $_('Generic Hellō Profile Picture')
												: source === 'upload'
												? $_('Uploaded from device')
												: $_('From {provider}', {
														values: {
															provider: getDisplay(slug)
														}
												  })}
										/>
										<span>
											{#if source === 'default'}
												{$_('Generic Hellō Profile Picture')}
											{:else if source === 'upload'}
												{#if Date.now() - Date.parse(createdAt) < 24 * 60 * 60 * 1000}
													{$_('Uploaded today')}
												{:else}
													{$_('Uploaded {date}', {
														values: {
															date: dayjs(createdAt).locale($locale).fromNow()
														}
													})}
												{/if}
											{:else}
												{$_('From {provider}', {
													values: {
														provider: source || getDisplay(slug)
													}
												})}

												{#if ['gravatar', 'twitter', 'github', 'gitlab', 'instagram'].includes(slug) && label}
													({label})
												{/if}

												{#if slug !== 'gravatar'}
													({Date.now() - Date.parse(updatedAt || createdAt) < 24 * 60 * 60 * 1000
														? updatedAt
															? $_('Updated today')
															: $_('Added today')
														: $_(updatedAt ? 'Updated {date}' : 'Added {date}', {
																values: {
																	date: dayjs(updatedAt || createdAt)
																		.locale($locale)
																		.fromNow()
																}
														  })})
												{/if}
											{/if}
										</span>
									</div>

									{#if confirmPictureDelete && confirmPictureDelete === picture}
										<Modal
											position="left"
											class="md:min-w-[22rem] top-10"
											on:close={() => (confirmPictureDelete = null)}
										>
											<div class="flex flex-col md:flex-row items-center justify-between">
												<span>{$_('Confirm Deletion')}</span>
												<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
													<button
														on:click={() => (confirmPictureDelete = null)}
														class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
													>
													<RemoveButton
														on:click={() => removePicture(index)}
														isLoading={removePictureAjax === picture}
														value="Delete"
													/>
												</div>
											</div>
										</Modal>
									{/if}
								</li>
							{/each}
						{/if}
					</ul>
				</section>
				{#if !editMode}
					<div
						class="relative flex items-center {$locale && $locale.startsWith('ar')
							? 'mr-6'
							: 'ml-6'} p-0.5"
						transition:slide|local
					>
						<Dropdown
							hasBackground={false}
							ariaLabel={$profileData.profile.pictures.filter((i) => !i.default).length
								? $_('Add another profile picture')
								: $_('Add a profile picture')}
							dataTest="add-profile-picture-btn"
							expanded={dropdownStates.picture}
							on:click={() => handleDropdown('picture')}
						>
							<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
								{$profileData.profile.pictures.filter((i) => !i.default).length
									? $_('Add another profile picture')
									: $_('Add a profile picture')}
							</div>

							{#if dropdownStates.picture}
								<div
									class="rounded-md text-left w-full z-30 p-4 space-y-3 flex flex-col"
									transition:slide|local
								>
									<!-- Picture upload start -->
									<button
										on:click={() => {
											uploadPictureAjax = true;
											document.getElementById('picture-upload-ref').click();
											setTimeout(() => {
												uploadPictureAjax = false;
											}, 2022);
											/**
											 * TODO
											 * This is a hack to prevent closing of picture dropdown,
											 * clickOutside is fired because of input file window?
											 */
											setTimeout(() => {
												dropdownStates.picture = true;
											}, 1);
										}}
										disabled={uploadPictureAjax}
										class="disabled:opacity-60 w-full relative btn-background overflow-hidden flex items-center {uploadPictureAjax
											? 'justify-center'
											: 'justify-start'} px-4"
									>
										<div class="w-full flex items-center justify-center">
											{#if uploadPictureAjax}
												<SpinnerIcon css="h-5 w-5 text-white dark:text-[#d4d4d4]" />
											{:else}
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="h-4.5"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														stroke-width="2"
														d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
													/>
												</svg>

												<span
													class="block text-left {$locale && $locale.startsWith('ar')
														? 'mr-4'
														: 'ml-4'}"
												>
													{$_('Upload from device')}
												</span>

												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="flex-shrink-0 {$locale && $locale.startsWith('ar')
														? 'mr-auto rotate-90'
														: 'ml-auto -rotate-90'} h-4.5 opacity-80 transform text-white dark:text-[#d4d4d4] group-hover:stroke-3"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
													stroke-width="2"
												>
													<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
												</svg>
											{/if}

											<input
												id="picture-upload-ref"
												type="file"
												accept="image/*"
												name="logo"
												hidden
												on:change={(e) =>
													(uploadedPicture = URL.createObjectURL(e.target.files[0]))}
												on:cancel={() => (uploadPictureAjax = false)}
											/>
										</div>
									</button>
									<!-- Picture upload end -->

									{#each logins.filter((i) => i.claims.picture) as provider, index}
										<LoginProvider
											prefix="Get picture from"
											on:click={(e) => continueWithProvider(provider, 'picture', e.detail)}
											{provider}
										/>
									{/each}
								</div>
							{/if}
						</Dropdown>
					</div>
				{/if}

				<section id="preferred-language" class="py-2 {!editMode ? 'mt-10' : 'mt-4'}">
					<h2 class="font-medium text-lg">{$_('Preferred Language')}</h2>
					<div
						class="relative flex items-center mt-2 {$locale && $locale.startsWith('ar')
							? 'mr-6'
							: 'ml-6'}"
					>
						<Dropdown
							hasBackground={false}
							dataTest="change-preferred-language-btn"
							ariaLabel={$locales.includes($locale)
								? $_($locale)
								: $locale.includes('-') && $locales.includes($locale.split('-')[0])
								? $_($locale.split('-')[0])
								: $_('en')}
							expanded={dropdownStates.language}
							on:click={() => handleDropdown('language')}
						>
							<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
								{#if $locales.includes($locale)}
									{$_($locale)}
								{:else if $locale.includes('-') && $locales.includes($locale.split('-')[0])}
									{$_($locale.split('-')[0])}
								{:else}
									{$_('en')}
								{/if}
							</div>

							{#if dropdownStates.language}
								<div
									class="rounded-md space-y-3 text-left w-full z-30 p-4 flex flex-col"
									transition:slide|local
								>
									{#each [...$locales].filter((i) => i !== $locale && !$locale.startsWith(i)) as l}
										<button
											data-test={`${l}-language-btn`}
											on:click={() => changePreferredLanguage(l)}
											class="px-3 {$locale && $locale.startsWith('ar')
												? 'text-right'
												: 'text-left'} btn-background {changePreferredLanguageAjax === l
												? 'flex items-center justify-center'
												: ''}"
											disabled={changePreferredLanguageAjax === l}
										>
											{#if changePreferredLanguageAjax === l}
												<SessionTimerModal css="h-6 w-6" />
											{:else}
												{$_(l)}
											{/if}
										</button>
									{/each}
								</div>
							{/if}
						</Dropdown>
					</div>
				</section>

				{#if editMode}
					<div transition:slide|local class="mt-4">
						<!-- Full Names -->
						<section data-test="name-section" class="py-2">
							<h3 class="opacity-80 text-sm">
								{$_('Full Names')}
							</h3>

							{#if $profileData.profile.names?.length}
								<ul class="mt-2 space-y-3">
									{#each dedupe($profileData.profile.names) as value, index (value)}
										<li
											data-test={`name-item-${index}`}
											class="relative flex items-center flex-shrink-0"
											animate:flip={{ duration: flip_animation_ms }}
											transition:slide|local
										>
											<div class="w-6 h-6 inline-flex items-center">
												{#if $profileData.profile.names.length > 1}
													<RemoveIconButton
														dataTest="remove-name-btn"
														on:click={() => (confirmFullNameDelete = value)}
													/>
												{/if}
											</div>

											<span>{value}</span>

											{#if confirmFullNameDelete && confirmFullNameDelete === value}
												<Modal
													dataTest="name-confirm-deletion-modal"
													position="left"
													class="md:min-w-[22rem] top-10"
													on:close={() => (confirmFullNameDelete = null)}
												>
													<div class="flex flex-col md:flex-row items-center justify-between">
														<span>{$_('Confirm Deletion')}</span>
														<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
															<button
																data-test="cancel-deletion-name-btn"
																on:click={() => (confirmFullNameDelete = null)}
																class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
															>
															<RemoveButton
																dataTest="confirm-deletion-name-btn"
																on:click={() => removeFullName(index)}
																isLoading={removeFullNameAjax === value}
																value="Delete"
															/>
														</div>
													</div>
												</Modal>
											{/if}
										</li>
									{/each}
								</ul>
							{/if}
						</section>

						<div
							class="relative w-3/4 md:w-[20rem] {$locale && $locale.startsWith('ar')
								? 'mr-6'
								: 'ml-6'}"
						>
							<button
								data-test="add-name-btn"
								on:click={() => (showAddFullNameModal = true)}
								class="mb-1 btn-background h-6 px-2 text-sm">{$_('Add full name')}</button
							>

							{#if showAddFullNameModal}
								<Modal position="left" on:close={() => (showAddFullNameModal = false)}>
									<VerifyName type="name" addName={addFullName} ajaxRequest={addFullNameAjax} />
								</Modal>
							{/if}
						</div>

						<!-- Preferred Names -->
						<section data-test="nickname-section" class="py-2">
							<h3 class="opacity-80 text-sm">
								{$_('Preferred Names')}
							</h3>

							{#if $profileData.profile.nicknames?.length}
								<ul class="mt-2 space-y-3">
									{#each dedupe($profileData.profile.nicknames) as value, index (value)}
										<li
											data-test={`nickname-item-${index}`}
											class="relative flex items-center flex-shrink-0"
											animate:flip={{ duration: flip_animation_ms }}
											transition:slide|local
										>
											<div class="w-6 h-6 inline-flex items-center">
												{#if $profileData.profile.nicknames.length > 1}
													<RemoveIconButton
														dataTest="remove-nickname-btn"
														on:click={() => (confirmPreferredNameDelete = value)}
													/>
												{/if}
											</div>

											<span>{value}</span>

											{#if confirmPreferredNameDelete && confirmPreferredNameDelete === value}
												<Modal
													dataTest="nickname-confirm-deletion-modal"
													position="left"
													class="md:min-w-[22rem] top-10"
													on:close={() => (confirmPreferredNameDelete = null)}
												>
													<div class="flex flex-col md:flex-row items-center justify-between">
														<span>{$_('Confirm Deletion')}</span>
														<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
															<button
																data-test="cancel-deletion-nickname-btn"
																on:click={() => (confirmPreferredNameDelete = null)}
																class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
															>
															<RemoveButton
																dataTest="confirm-deletion-nickname-btn"
																on:click={() => removePreferredName(index)}
																isLoading={removePreferredNameAjax === value}
																value="Delete"
															/>
														</div>
													</div>
												</Modal>
											{/if}
										</li>
									{/each}
								</ul>
							{/if}
						</section>

						<div
							class="relative w-3/4 md:w-[20rem] {$locale && $locale.startsWith('ar')
								? 'mr-6'
								: 'ml-6'}"
						>
							<button
								data-test="add-nickname-btn"
								on:click={() => (showAddPreferredNameModal = true)}
								class="mb-1 btn-background h-6 px-2 text-sm">{$_('Add preferred name')}</button
							>

							{#if showAddPreferredNameModal}
								<Modal position="left" on:close={() => (showAddPreferredNameModal = false)}>
									<VerifyName
										type="nickname"
										addName={addPreferredName}
										ajaxRequest={addPreferredNameAjax}
									/>
								</Modal>
							{/if}
						</div>

						<!-- First Names -->
						<section data-test="given_name-section" class="py-2">
							<h3 class="opacity-80 text-sm">
								{$_('First Names')}
							</h3>

							{#if $profileData.profile.given_names?.length}
								<ul class="mt-2 space-y-3">
									{#each dedupe($profileData.profile.given_names) as value, index (value)}
										<li
											data-test="given_name-item-{index}"
											class="relative flex items-center flex-shrink-0"
											animate:flip={{ duration: flip_animation_ms }}
											transition:slide|local
										>
											<div class="w-6 h-6 inline-flex items-center">
												{#if $profileData.profile.given_names.length > 1}
													<RemoveIconButton
														dataTest="remove-given_name-btn"
														on:click={() => (confirmFirstNameDelete = value)}
													/>
												{/if}
											</div>

											<span>{value}</span>

											{#if confirmFirstNameDelete && confirmFirstNameDelete === value}
												<Modal
													dataTest="given_name-confirm-deletion-modal"
													position="left"
													class="md:min-w-[22rem] top-10"
													on:close={() => (confirmFirstNameDelete = null)}
												>
													<div class="flex flex-col md:flex-row items-center justify-between">
														<span>{$_('Confirm Deletion')}</span>
														<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
															<button
																data-test="cancel-deletion-given_name-btn"
																on:click={() => (confirmFirstNameDelete = null)}
																class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
															>
															<RemoveButton
																dataTest="confirm-deletion-given_name-btn"
																on:click={() => removeFirstName(index)}
																isLoading={removeFirstNameAjax === value}
																value="Delete"
															/>
														</div>
													</div>
												</Modal>
											{/if}
										</li>
									{/each}
								</ul>
							{/if}
						</section>

						<div
							class="relative w-3/4 md:w-[20rem] {$locale && $locale.startsWith('ar')
								? 'mr-6'
								: 'ml-6'}"
						>
							<button
								data-test="add-given_name-btn"
								on:click={() => (showAddFirstNameModal = true)}
								class="mb-1 btn-background h-6 px-2 text-sm">{$_('Add first name')}</button
							>

							{#if showAddFirstNameModal}
								<Modal position="left" on:close={() => (showAddFirstNameModal = false)}>
									<VerifyName
										type="given-name"
										addName={addFirstName}
										ajaxRequest={addFirstNameAjax}
									/>
								</Modal>
							{/if}
						</div>

						<!-- Last Names -->
						<section data-test="family_name-section" class="py-2">
							<h3 class="opacity-80 text-sm">
								{$_('Last Names')}
							</h3>

							{#if $profileData.profile.family_names?.length}
								<ul class="mt-2 space-y-3">
									{#each dedupe($profileData.profile.family_names) as value, index (value)}
										<li
											data-test="family_name-item-{index}"
											class="relative flex items-center flex-shrink-0"
											animate:flip={{ duration: flip_animation_ms }}
											transition:slide|local
										>
											<div class="w-6 h-6 inline-flex items-center">
												{#if $profileData.profile.family_names.length > 1}
													<RemoveIconButton
														dataTest="remove-family_name-btn"
														on:click={() => (confirmLastNameDelete = value)}
													/>
												{/if}
											</div>

											<span>{value}</span>

											{#if confirmLastNameDelete && confirmLastNameDelete === value}
												<Modal
													dataTest="family_name-confirm-deletion-modal"
													position="left"
													class="md:min-w-[22rem] top-10"
													on:close={() => (confirmLastNameDelete = null)}
												>
													<div class="flex flex-col md:flex-row items-center justify-between">
														<span>{$_('Confirm Deletion')}</span>
														<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
															<button
																data-test="cancel-deletion-family_name-btn"
																on:click={() => (confirmLastNameDelete = null)}
																class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
															>
															<RemoveButton
																dataTest="confirm-deletion-family_name-btn"
																on:click={() => removeLastName(index)}
																isLoading={removeLastNameAjax === value}
																value="Delete"
															/>
														</div>
													</div>
												</Modal>
											{/if}
										</li>
									{/each}
								</ul>
							{/if}
						</section>

						<div
							class="relative w-3/4 md:w-[20rem] {$locale && $locale.startsWith('ar')
								? 'mr-6'
								: 'ml-6'}"
						>
							<button
								data-test="add-family_name-btn"
								on:click={() => (showAddLastNameModal = true)}
								class="mb-1 btn-background h-6 px-2 text-sm">{$_('Add last name')}</button
							>

							{#if showAddLastNameModal}
								<Modal position="left" on:close={() => (showAddLastNameModal = false)}>
									<VerifyName
										type="family-name"
										addName={addLastName}
										ajaxRequest={addLastNameAjax}
									/>
								</Modal>
							{/if}
						</div>
					</div>
				{/if}

				<table id="providers" class="mt-10 table-fixed w-full">
					<thead>
						<tr class="relative">
							<th class="w-1/2 md:w-3/5 text-left text-lg font-medium">
								<div class="flex items-center justify-between">
									<div class="flex items-center">
										<span>{$_('Providers')}</span>
										<HelpButton
											ariaLabel="Help"
											content={$_('Providers you can use to log in or recover your Hellō Wallet')}
											placement="top"
										/>
									</div>
								</div>
							</th>
						</tr>
					</thead>
				</table>

				<div class="mt-2 w-full">
					{#if verifyProviderAuthority}
						<FullPageModal dataTest="verify-preferred-modal" on:close={closeModal}>
							<button aria-label="Close" on:click={closeModal} class="group absolute right-4 top-6">
								<CloseIcon />
							</button>
							{#if verifyProviderAuthority.slug === 'email'}
								<h3 class="mb-4 font-medium text-center">
									{$_('Verify your email')}
								</h3>
								<VerifyEmail
									email={verifyProviderAuthority.user_name}
									disabled
									verify
									on:success={verifyContactAuthoritySuccess}
								/>
							{:else if verifyProviderAuthority.slug === 'phone'}
								<h3 class="mb-4 font-medium text-center">
									{$_('Verify your phone')}
								</h3>
								<VerifyPhone
									phone={verifyProviderAuthority.user_name}
									disabled
									verify
									on:success={verifyContactAuthoritySuccess}
								/>
							{:else if verifyProviderAuthority.slug === 'ethereum'}
								<h3 class="mb-4 font-medium text-center">
									{$_('Verify your account')}
								</h3>
								<div class="relative h-auto btn-border rounded-md hover-none p-3 space-y-3">
									<span class="mb-3 block text-left"
										>{verifyProviderAuthority.wallet?.name || 'Ethereum'}
										{#if verifyProviderAuthority.wallet?.display}
											<span
												use:tippy={{
													content: verifyProviderAuthority.login_hint,
													placement: 'top'
												}}
											>
												({verifyProviderAuthority.wallet?.display})
											</span>
										{/if}
									</span>
									{#if verifyProviderAuthority.slug === 'ethereum' && window.ethereum}
										<LoginProvider
											autofocus={true}
											on:ethereum={verifyEthereumForAuthority}
											hideusername
											provider={verifyProviderAuthority}
											prefix="Verify with"
										/>
									{/if}

									<!-- <button
										autofocus
										class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
										on:click={() => verifyWalletConnectForPreferred(verifyProviderAuthority)}
									>
										<div class="flex items-center gap-x-4">
											<img
												src="https://cdn.hello.coop/images/walletconnect.svg"
												alt="WalletConnect"
												class="w-4.5 max-h-[18px]"
											/>
											<span class="block text-left">
												{$_('Continue with {provider}', { values: { provider: 'WalletConnect' } })}
											</span>
										</div>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="flex-shrink-0 {$locale && $locale.startsWith('ar')
												? 'mr-auto rotate-90'
												: 'ml-auto -rotate-90'} h-4.5 opacity-80 transform group-focus:stroke-3 group-hover:stroke-3"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											stroke-width="2"
										>
											<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
										</svg>
									</button> -->
								</div>
							{:else}
								<h3 class="mb-4 font-medium text-center">
									{$_('Verify your account')}
								</h3>
								<LoginProvider
									on:click={verifyProviderForAuthority}
									provider={verifyProviderAuthority}
									prefix="Verify with"
									autofocus={true}
								/>
							{/if}
						</FullPageModal>
					{/if}

					{#if verifyProvider}
						<FullPageModal dataTest="verify-modal" on:close={closeModal}>
							<button
								aria-label="Close"
								on:click={closeModal}
								class="group absolute right-4 top-6 z-[60]"
							>
								<CloseIcon />
							</button>
							<div class="mb-4 opacity-80 flex items-center justify-center">
								{#if $profileData.profile.unverified_emails.includes(verifyProvider)}
									<MailIcon />
								{:else if $profileData.profile.unverified_phones.includes(verifyProvider)}
									<PhoneIcon />
								{/if}
								<h3 class="font-medium text-center ml-2">
									{$profileData.profile.unverified_emails.includes(verifyProvider)
										? $_('Verify your email')
										: $_('Verify your email')}
								</h3>
							</div>
							{#if $profileData.profile.unverified_emails.includes(verifyProvider)}
								<VerifyEmail
									email={verifyProvider}
									disabled
									verify
									on:success={verifyEmailSuccess}
									on:error={verifyContactError}
								/>
							{:else if $profileData.profile.unverified_phones.includes(verifyProvider)}
								<VerifyPhone
									phone={verifyProvider}
									disabled
									verify
									on:success={verifyPhoneSuccess}
									on:error={verifyContactError}
								/>
							{/if}
						</FullPageModal>
					{/if}

					<div class="w-full">
						<!-- Checking doneWizardAt here because we don't want to sort accounts in wizard flow -->
						{#if $profileData.profile.accounts.length && $profileData.profile?.actions?.doneWizardAt}
							{#each dedupe($profileData.profile.accounts, 'id').sort(sort.accounts) as account, index (account.id)}
								{@const recoveryCount = $profileData.profile.accounts.filter(
									(i) => !i.preferred && i.recovery
								).length}
								<div
									data-test="profile-provider-{index}"
									class="relative flex items-center justify-between"
									animate:flip={{ duration: flip_animation_ms }}
									transition:slide|local
								>
									<!-- (account.verifiedAt check) Delete verified vs unverified -->
									{#if confirmProviderDelete && (account.verifiedAt ? confirmProviderDelete === account.id : confirmProviderDelete === account.user_name)}
										<Modal
											position="left"
											class="md:min-w-[22rem] top-10"
											on:close={() => (confirmProviderDelete = null)}
										>
											<div class="flex flex-col md:flex-row items-center justify-between">
												<span>{$_('Confirm Deletion')}</span>
												<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
													<button
														on:click={() => (confirmProviderDelete = null)}
														class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
													>
													<RemoveButton
														dataTest="remove-provider-{index}-btn-confirm"
														on:click={() => {
															account.verifiedAt
																? removeProvider(account.id)
																: removeUnverifiedProvider(account.user_name);
														}}
														isLoading={account.verifiedAt
															? removeProviderAjax === account.id
															: removeUnverifiedProviderAjax === account.user_name}
														value="Delete"
													/>
												</div>
											</div>
										</Modal>
									{/if}

									<div class="flex truncate items-start md:items-center w-full">
										<div
											class="w-6 flex-shrink-0 py-3 md:py-0 inline-flex items-center justify-start mt-1 md:mt-0"
										>
											{#if editMode && !account.preferred && !($profileData.profile.accounts.filter((i) => i.recovery && !i.preferred).length <= 3 && account.recovery)}
												<RemoveIconButton
													dataTest="remove-provider-{index}-btn"
													on:click={() =>
														account.verifiedAt
															? (confirmProviderDelete = account.id)
															: (confirmProviderDelete = account.user_name)}
												/>
											{/if}
										</div>
										<div class="relative truncate flex items-start md:items-center w-full">
											<span class="w-7 flex-shrink-0 mt-1 md:mt-0 py-3 md:py-2">
												{#if account.slug === 'ethereum' && account.wallet}
													<img
														src={account.wallet.icon}
														alt={account.wallet.name}
														class="w-4.5 max-h-[18px]"
													/>
												{:else if account.slug === 'email'}
													<MailIcon />
												{:else if account.slug === 'phone'}
													<PhoneIcon />
												{:else if account.mastodonServer}
													<img
														src="https://{account.mastodonServer}/favicon.ico"
														alt="Mastodon"
														class="w-4.5 max-h-[18px]"
													/>
												{:else}
													<img
														src="https://cdn.hello.coop/images/{account.slug}.svg"
														alt={account.slug}
														class="w-4.5 max-h-[18px] {['apple', 'twitter'].includes(account.slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
															? 'invert dark:invert-20'
															: ''}"
													/>
												{/if}
											</span>
											<div class="w-full truncate flex flex-col md:flex-row py-3 md:py-2 px-0.5">
												<span
													data-test="profile-provider-{index}-label"
													class="flex-1 truncate font-medium"
													class:font-bold={account.preferred}
													class:text-red-500={!account.verifiedAt &&
														['email', 'phone'].includes(account.slug)}
												>
													{#if account.slug === 'phone'}
														{window.intlTelInputUtils.formatNumber(
															account.user_name,
															null,
															window.intlTelInputUtils.numberFormat.INTERNATIONAL
														)}
													{:else if account.slug === 'email'}
														{account.user_name}
													{:else if account.slug === 'ethereum'}
														<span
															use:tippy={{
																content: account.login_hint,
																placement: 'top'
															}}
														>
															{#if account.wallet}
																{account.wallet.name} ({account.user_name})
															{:else}
																Ethereum ({account.user_name})
															{/if}
														</span>
													{:else}
														{getDisplay(account.slug)}
														{account.mastodonServer && account.user_name
															? `(@${account.user_name}@${account.mastodonServer})`
															: account.user_name
															? `(${account.user_name})`
															: ''}
													{/if}
												</span>
												<div
													class="gap-x-2 flex justify-start items-center {$locale &&
													$locale.startsWith('ar')
														? 'mr-0 md:mr-3'
														: 'ml-0 md:ml-3'} mt-1 md:mt-0"
												>
													{#if !account.preferred && account.recovery && account.verifiedAt && !account.linkedAccount}
														<button
															data-test="demote-{index}-btn"
															transition:fly|local={{ x: 10 }}
															on:click={() => removeRecovery(account)}
															class="btn-border h-4.5 w-4.5"
															disabled={recoveryCount <= 3}
														>
															<DemoteIcon />
														</button>
													{/if}

													{#if account.preferred}
														<span
															class="inline-block text-sm font-bold uppercase truncate
														{$locale && $locale.startsWith('ar')
																? 'text-right md:text-left'
																: 'text-left md:text-right'} flex-shrink-0"
														>
															{$_('Preferred')}
														</span>
													{:else if account.communal}
														<span
															class="inline-block text-sm font-bold uppercase truncate
														{$locale && $locale.startsWith('ar')
																? 'text-right md:text-left'
																: 'text-left md:text-right'} flex-shrink-0"
														>
															{$_('Communal')}
														</span>
													{:else if account.recovery}
														<span
															transition:fly|local={{ x: 10 }}
															class="inline-block text-sm font-bold uppercase truncate
														{$locale && $locale.startsWith('ar')
																? 'text-right md:text-left'
																: 'text-left md:text-right'} flex-shrink-0"
														>
															{$_('Recovery')}
														</span>
													{/if}

													{#if !account.verifiedAt && ['email', 'phone'].includes(account.slug)}
														<button
															data-test="verify-{index}-btn"
															class="btn-background font-bold text-xs h-5 w-20 truncate px-1 uppercase"
															on:click={() => (verifyProvider = account.user_name)}
														>
															<span class="truncate">{$_('Verify')}</span>
														</button>
													{:else if account.preferred}
														<!-- <span class="text-xs font-semibold" /> -->
													{:else if account.communal}
														<!-- <span /> -->
														<!-- Phone can only be added as recovery and not preferred -->
													{:else if account.recovery && !['phone'].includes(account.slug)}
														<!-- <PutAuthorityButton
															dataTest="promote-{index}-btn"
															on:click={() => changePreferred(account)}
															value="Make Preferred"
															isLoading={changePreferredAjax === account.id}
														/> -->
													{/if}

													{#if account.linkedAccount}
														{logins.find((i) => i.slug === account.linkedAccount).display}
													{/if}

													{#if account.preferred}
														<HelpButton
															css="!-ml-0 w-4.5 h-4.5"
															ariaLabel="Help"
															content={$_(
																'You log in with your preferred provider. If you lose access to your preferred provider, you can log in with two recovery providers'
															)}
															placement="top"
														/>
													{:else if account.linkedAccount}
														<img
															src="https://cdn.hello.coop/images/{account.linkedAccount}.svg"
															alt={account.linkedAccount}
															class="w-4.5 max-h-[18px] {['apple', 'twitter'].includes(
																account.linkedAccount
															) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
																? 'invert dark:invert-20'
																: ''}"
														/>
													{:else if ['instagram'].includes(account.slug) || (account.slug === 'phone' && account.recovery)}
														<span class="w-4.5 h-4.5">
															<NotAllowedIcon />
														</span>
													{:else if account.verifiedAt}
														<button
															data-test="promote-{index}-btn"
															on:click={() =>
																account.recovery ? makePreferred(account) : makeRecovery(account)}
															class="btn-border h-4.5 w-4.5"
														>
															<PromoteIcon />
														</button>
													{/if}
												</div>
											</div>
										</div>
									</div>
								</div>
							{/each}
						{/if}
					</div>
				</div>

				{#if !editMode}
					<section
						class="space-y-3 mt-2 {$locale && $locale.startsWith('ar') ? 'mr-6' : 'ml-6'} p-0.5"
						transition:slide|local
					>
						{#if recoveriesLength < 2}
							<button
								on:click={() => (dropdownStates.provider = true)}
								class="btn-danger bg-red-500 text-white dark:text-charcoal w-full rounded-md py-2 px-4 flex items-center justify-start text-sm"
							>
								<div class="w-7 flex-shrink-0">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										class="h-5"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										stroke-width="2"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
										/>
									</svg>
								</div>
								<div
									class="flex flex-col {$locale && $locale.startsWith('ar')
										? 'text-right'
										: 'text-left'}"
								>
									<span class="font-bold uppercase">{$_('Wallet Recovery Not Set Up')}</span>
									{#if recoveriesLength === 1}
										<span class="text-xs italic">{$_('Link one more provider to complete')}</span>
									{:else}
										<span class="text-xs italic"
											>{$_('Link two or more providers to complete')}</span
										>
									{/if}
								</div>
							</button>
						{/if}

						<div class="relative flex items-center">
							<Dropdown
								hasBackground={false}
								dataTest="add-provider-btn"
								ariaLabel={$_('Link another provider')}
								expanded={dropdownStates.provider}
								on:click={() => handleDropdown('provider')}
							>
								<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
									{$_('Link another provider')}
								</div>

								{#if dropdownStates.provider}
									<div
										class="rounded-md text-left w-full z-30 p-4 flex flex-col"
										transition:slide|local
									>
										<LoginProviderGroup
											prefix="Link"
											on:ethereum={continueWithEthereumExtension}
											on:walletconnect={continueWithWalletConnect}
											showInstagram={true}
										/>
									</div>
								{/if}
							</Dropdown>
						</div>

						<Dropdown
							hasBackground={false}
							id="email-container"
							dataTest="add-email-btn"
							ariaLabel={$profileData.profile.accounts.find((i) => i.slug === 'email')
								? $_('Verify another email')
								: $_('Verify email')}
							expanded={dropdownStates.email}
							on:click={() => handleDropdown('email')}
						>
							<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4 truncate">
								<MailIcon />
								<span class="text-left" aria-hidden="true">
									{$profileData.profile.accounts.find((i) => i.slug === 'email')
										? $_('Verify another email')
										: $_('Verify email')}
								</span>
							</div>

							{#if dropdownStates.email}
								<div class="p-4" transition:slide|local>
									<VerifyEmail
										on:otp={() => (emailOTPState = true)}
										on:success={verifyEmailSuccess}
										on:error={verifyContactError}
									/>

									{#if !emailOTPState && logins.filter((i) => i.claims.verified_email).length}
										<section class="space-y-3 mt-5" transition:slide|local>
											{#each logins.filter((i) => i.claims.verified_email) as provider}
												<LoginProvider
													on:click={(e) => continueWithProvider(provider, 'email', e.detail)}
													{provider}
													prefix="Get email from"
												/>
											{/each}
										</section>
									{/if}
								</div>
							{/if}
						</Dropdown>

						<Dropdown
							hasBackground={false}
							dataTest="add-phone-btn"
							ariaLabel={$profileData.profile.accounts.find((i) => i.slug === 'phone')
								? $_('Verify another phone')
								: $_('Verify phone')}
							expanded={dropdownStates.phone}
							on:click={() => handleDropdown('phone')}
						>
							<div class="h-12 w-full flex items-center justify-start p-4 gap-x-4">
								<PhoneIcon />
								<span class="text-left block" aria-hidden="true"
									>{$profileData.profile.accounts.find((i) => i.slug === 'phone')
										? $_('Verify another phone')
										: $_('Verify phone')}
								</span>
							</div>

							{#if dropdownStates.phone}
								<div class="p-4" transition:slide|local>
									<VerifyPhone
										on:otp={() => (phoneOTPState = true)}
										on:success={verifyPhoneSuccess}
										on:error={verifyContactError}
									/>

									{#if !phoneOTPState && logins.filter((i) => i.claims.verified_phone).length}
										<section class="space-y-3 mt-5" transition:slide|local>
											{#each logins.filter((i) => i.claims.verified_phone) as provider}
												<div>
													<LoginProvider
														on:click={(e) => continueWithProvider(provider, 'phone', e.detail)}
														{provider}
														prefix="Get phone from"
													/>
												</div>
											{/each}
										</section>
									{/if}
								</div>
							{/if}
						</Dropdown>
					</section>
				{/if}

				<section id="applications" class="mt-10">
					<h2 class="font-medium text-lg">{$_('Applications')}</h2>

					{#if $profileData.profile.applications?.length}
						<ul class="mt-2 space-y-4">
							{#each dedupe([...$profileData.profile.applications], 'id').sort(sort.apps) as application, index (application.id)}
								<li
									class="flex relative items-end"
									animate:flip={{ duration: flip_animation_ms }}
									transition:slide|local
								>
									{#if confirmApplicationDelete && confirmApplicationDelete === application.id}
										<Modal
											position="left"
											class="md:min-w-[22rem] top-10"
											on:close={() => (confirmApplicationDelete = null)}
										>
											<div class="flex flex-col md:flex-row items-center justify-between">
												<span>{$_('Confirm Action')}</span>
												<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
													<button
														on:click={() => (confirmApplicationDelete = null)}
														class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
													>
													<RemoveButton
														on:click={removeApplication}
														isLoading={removeApplicationAjax === application.id}
														value="Forget"
													/>
												</div>
											</div>
										</Modal>
									{/if}

									<div class="w-6 h-6 flex-shrink-0 mb-7">
										{#if editMode}
											<RemoveIconButton
												on:click={() => (confirmApplicationDelete = application.id)}
											/>
										{/if}
									</div>

									<div class="w-12 h-12 inline-flex items-center justify-center flex-shrink-0">
										<!-- <img
											src={application.image_uri}
											alt={application.name || application.name}
											class="h-8 w-8 object-contain"
										/> -->
									</div>

									<div
										class="{$locale && $locale.startsWith('ar')
											? 'mr-2 items-start'
											: 'ml-2'} flex flex-col"
									>
										{#if application.image_uri || application.dark_image_uri}
											<picture>
												<source
													srcset={application.dark_image_uri || application.image_uri}
													media="(prefers-color-scheme: dark)"
												/>
												<img
													src={application.image_uri}
													alt={application.name}
													class="max-w-[8rem] max-h-[1.8rem] object-contain object-left"
												/>
											</picture>
										{/if}

										<span class="mt-1">
											{application.name}
										</span>

										<span class="text-xs italic">
											{$_('First login {date}', {
												values: {
													date: dayjs(application.firstRelease?.at).locale($locale).fromNow()
												}
											})}

											{#if application.firstRelease?.location?.City || application.firstRelease?.location?.Country}
												•
												{application.firstRelease?.location?.City
													? `${application.firstRelease?.location?.City},`
													: ''}
												{application.firstRelease?.location?.Country}
											{/if}
										</span>
										<span class="text-xs italic">
											{#if application.lastRelease?.at}
												{$_('Last login {date}', {
													values: {
														date: dayjs(application.lastRelease?.at).locale($locale).fromNow()
													}
												})}
											{:else}
												{$_('Last login {date}', {
													values: {
														date: dayjs(application.firstRelease?.at).locale($locale).fromNow()
													}
												})}
											{/if}

											{#if application.firstRelease?.location?.City || application.firstRelease?.location?.Country || application.lastRelease?.location?.City || application.lastRelease?.location?.Country}
												•
												{application.lastRelease?.location.City
													? `${application.lastRelease?.location?.City},`
													: `${application.firstRelease?.location?.City},`}
												{application.lastRelease?.location?.Country ||
													application.firstRelease?.location?.Country}
											{/if}
										</span>
									</div>
								</li>
							{/each}
						</ul>
					{:else}
						<span
							class="text-fallback text-sm mt-2 {$locale && $locale.startsWith('ar')
								? 'mr-6'
								: 'ml-6'}">{$_('No applications')}</span
						>
					{/if}
				</section>

				<section id="devices" class="mt-10">
					<div class="flex items-center justify-between">
						<h2 class="font-medium text-lg">{$_('Devices')}</h2>
						<!-- <div class="inline-flex items-center">
							<span class="text-sm">No Login Prompt</span>
							<HelpButton
								ariaLabel="Help"
								content={$_('Remembers your login choice on each device')}
								placement="top"
							/>
						</div> -->
					</div>

					{#if $profileData.profile.devices?.length}
						{@const devices = $profileData.profile?.devices?.filter(
							(i) =>
								i.id === $profileData.profile?.currentDevice ||
								(i.createdAt?.at && i.lastAccess?.at && i.createdAt?.at !== i.lastAccess?.at)
						)}
						<ul class="mt-2 space-y-4">
							{#each dedupe(devices, 'id').sort( (a, b) => sort.devices($profileData.profile.currentDevice, a, b) ) as device (device.id)}
								<li
									class="flex relative items-start"
									animate:flip={{ duration: flip_animation_ms }}
									transition:slide|local
								>
									{#if confirmDeviceDelete && confirmDeviceDelete === device.id}
										<Modal
											position="left"
											class="md:min-w-[22rem] top-10"
											on:close={() => (confirmDeviceDelete = null)}
										>
											<div class="flex flex-col md:flex-row items-center justify-between">
												<span>{$_('Confirm Action')}</span>
												<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
													<button
														on:click={() => (confirmDeviceDelete = null)}
														class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
													>
													<RemoveButton
														on:click={removeDevice}
														isLoading={removeDeviceAjax === device.id}
														value="Forget"
													/>
												</div>
											</div>
										</Modal>
									{/if}

									<div class="w-6 h-6 flex-shrink-0 mt-1">
										{#if editMode && $profileData.profile.currentDevice !== device.id}
											<RemoveIconButton on:click={() => (confirmDeviceDelete = device.id)} />
										{/if}
									</div>

									<span class="-mt-1 w-12 h-12 relative inline-flex items-center justify-center">
										{#if parser(device.created.userAgent).os.name.includes('Mac') || parser(device.created.userAgent).os.name.includes('iOS')}
											<span class="absolute">
												<AppleIcon />
											</span>
										{:else if parser(device.created.userAgent).os.name.includes('Android')}
											<span class="absolute">
												<AndroidIcon />
											</span>
										{:else if parser(device.created.userAgent).os.name.includes('Linux')}
											<span class="absolute">
												<LinuxIcon />
											</span>
										{:else if parser(device.created.userAgent).os.name.includes('Windows')}
											<span
												class="absolute"
												class:mb-2={parser(device.created.userAgent).device.type == undefined}
											>
												<WindowsIcon />
											</span>
										{/if}

										{#if parser(device.created.userAgent).device.type === 'mobile'}
											<MobileIcon />
										{:else if parser(device.created.userAgent).device.type === 'tablet'}
											<TabletIcon />
										{:else}
											<DesktopIcon />
										{/if}
									</span>

									<div
										class="{$locale && $locale.startsWith('ar') ? 'mr-2' : 'ml-2'} flex flex-col"
									>
										<span
											>{parser(device.created.userAgent).browser.name}
											{parser(device.created.userAgent).os.name}</span
										>
										<span class="text-xs italic">
											{$_('First login {date}', {
												values: {
													date: dayjs(device.createdAt).locale($locale).fromNow()
												}
											})}

											{#if device.created?.location?.City || device.created?.location?.Country}
												•
												{device.created?.location?.City ? `${device.created?.location?.City},` : ''}
												{device.created?.location?.Country}
											{/if}
										</span>
										<span
											class="text-xs italic"
											class:font-semibold={$profileData.profile.currentDevice === device.id}
										>
											{#if $profileData.profile.currentDevice === device.id}
												{$_('Current device')}
											{:else if device.lastAccess}
												{$_('Last login {date}', {
													values: {
														date: dayjs(device.lastAccess?.at).locale($locale).fromNow()
													}
												})}
											{:else}
												{$_('Last login {date}', {
													values: {
														date: dayjs(device.createdAt).locale($locale).fromNow()
													}
												})}
											{/if}

											{#if device.lastAccess?.location.City || device.created?.location?.City || device.lastAccess?.location?.Country || device.created?.location?.Country}
												•
												{device.lastAccess?.location.City
													? `${device.lastAccess?.location.City},`
													: `${device.created?.location.City},`}
												{device.lastAccess?.location?.Country || device.created?.location?.Country}
											{/if}
										</span>
									</div>

									<!-- <input
										on:click={(e) => togglePrompt(e.target.checked, device.id)}
										type="checkbox"
										name="no_prompt"
										checked={device.noPrompt}
										class="form-checkbox"
									/> -->
								</li>
							{/each}
						</ul>
					{:else}
						<span class="text-fallback text-sm mt-2">{$_('No devices')}</span>
					{/if}
				</section>

				<section class="my-8 relative">
					<div class="mt-2 flex justify-between items-center flex-wrap gap-4">
						<button
							on:click={downloadData}
							class="btn-border inline-flex items-center px-4 h-9 font-medium gap-x-1"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="h-5 w-5"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								stroke-width="2"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
								/>
							</svg>
							<span class="mt-0.5">{$_('Download Your Data')}</span>
						</button>
						<!-- svelte-ignore a11y-missing-content -->
						<a id="downloadAnchorElem" hidden />

						{#if editMode}
							<button
								data-test="profile-delete-btn"
								on:click={() => (confirmProfileDelete = true)}
								class="btn-danger inline-flex items-center px-4 h-9 font-medium gap-x-1"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-5 w-5"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fill-rule="evenodd"
										d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
										clip-rule="evenodd"
									/>
								</svg>
								<span class="mt-0.5">{$_('Delete Your Wallet')}</span>
							</button>
						{/if}
					</div>

					{#if editMode && confirmProfileDelete}
						<FullPageModal
							dataTest="profile-delete-modal"
							on:close={() => {
								confirmProfileDelete = false;
								deleteConfirmationInput = '';
							}}
						>
							<button
								on:click={() => {
									confirmProfileDelete = false;
									deleteConfirmationInput = '';
								}}
								aria-label="Close"
								class="group absolute right-4 top-6"
							>
								<CloseIcon />
							</button>
							<p class="text-center">
								{@html $_(
									'Once you delete your wallet,<br/>there is no going back<br/>Please type DELETE to confirm'
								)}
							</p>
							<form on:submit|preventDefault={delProfile}>
								<!-- svelte-ignore a11y-autofocus -->
								<input
									autofocus
									data-test="profile-delete-input"
									bind:value={deleteConfirmationInput}
									type="text"
									name="delete-confirmation-input"
									class="my-4 h-12 px-4 w-full"
									placeholder="DELETE"
								/>
								<button
									data-test="confirm-profile-delete-btn"
									disabled={deleteConfirmationInput !== 'DELETE' || delProfileAjax}
									type="submit"
									class="w-full h-12 btn-danger-background inline-flex items-center justify-center"
								>
									{#if delProfileAjax}
										<SpinnerIcon css="h-6 w-6" />
									{:else}
										{$_('Permanently delete your wallet')}
									{/if}
								</button>
							</form>
						</FullPageModal>
					{/if}
				</section>
			</div>
		</main>
		<wc-footer use:setAttributes />
	{/if}
{/if}
