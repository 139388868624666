import Login from './login.svelte';
import Welcome from './welcome.svelte';
import Status from './status.svelte';
import RecoveryProvider from './recoveryprovider.svelte';
import Profile from './index.svelte';
import Incomplete from './incomplete.svelte';
import Upgrade from './upgrade.svelte';
import Passkey from './passkey.svelte';

export default {
	'/': Profile,
	'/login': Login,
	'/wizard/welcome': Welcome,
	'/wizard/status': Status,
	'/wizard/recoveryprovider': RecoveryProvider,
	'/wizard/incomplete': Incomplete,
	'/wizard/upgrade': Upgrade,
	'/passkey': Passkey,
	// The catch-all route must always be last
	'*': Login
};
